<template>
  <div class="branches-map">
    <div id="map" style="width:100%; height:100%"></div>
  </div>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, onMounted } from '@vue/runtime-core';
import { projectFirestore } from '../firebase/config';
export default {
    setup(){
        let map;
        const roman_icon = 'https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Frosman_market_small_logo-marker_25x25_375x375.png?alt=media&token=f31fdda0-cdf4-4ffd-9f04-ec88c3f682a4'
        async function initMap() {
            map = new google.maps.Map(document.getElementById("map"), {
                center: { lat: 32.2, lng:  35.217018},
                zoom: 8.9,
                scaleControl: true,
            });

            const docs = await projectFirestore.collection('Branches').get()
            const branches = docs.docs.map(doc=>doc.data())
            
            
            branches.forEach((branche)=>{
                if(branche.branche_name!='דיוטי פארם נו"ש'){
                    const contentString = `<h2>${branche.branche_name} - ${branche.address}</h2>`
                    const infowindow = new google.maps.InfoWindow({
                        content: contentString,
                    });
                    
                    google.maps.event.addListener(map, "click", function() {
                        infowindow.close();
                    });

                    const marker = new google.maps.Marker({
                        position: { lat: branche.lat, lng:  branche.lng},
                        map: map,
                        title: `סניף ${branche.branche_name}`,
                        icon: roman_icon
                    })

                    marker.addListener("click", () => {
                        infowindow.open({
                            anchor: marker,
                            map,
                            shouldFocus: true,
                        });
                    });


                }
            })
        }
        onMounted(() => {
            initMap();
        })
        return {

        }
    }
}
</script>

<style scoped>
    .branches-map{
        width: 100%;
        height: 100%;
    }
    :global(.gm-style-iw-d){
        margin: 10px;
        text-align: center;
        color: #333;
        overflow: hidden !important;
    }    

</style>